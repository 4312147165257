.summary_rating_block {
  display: flex !important;
  flex-direction: row !important;
  gap: 5px !important;
  flex-wrap: wrap !important;
}

.summary_rating_container {
  display: flex;
  flex-direction: row !important;
  padding: 8px 0px 0px 2px;
  gap: 5px;
}

.rating_block {
  height: 12px;
  margin-top: 1px;
  width: 12px;
  border-radius: 3px;
}
.summary_rating {
  height: 18px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15.08px;
  text-wrap: nowrap !important;
}
