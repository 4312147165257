@import "../colors/colors";
@import "../mixins/padding";

.guardian-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    .guardian-title {
        font-size: 20px;
        color: $title-charcoal;
    }
    .guardian-subtitle {
        color: $secondary-Taupe-Gray;
        font-size: 14px;
        margin: 5px 0px 8px 0px;
    }
    button {
        color: $secondary-white;
    }
}
.payments-subtitle{
    color: $title-charcoal;
    font-size: 14px;
    margin: 5px 0px 15px 0px;
}
.guardian__svg{
    svg{
        width: inherit;
    height: 40px;
    }
   
}
.guardian__desc{
    font-size: 14px;
    line-height: 20px;
    color: $title-charcoal;
    margin-top: 24px;
    margin-bottom: 15px;
}
.guardian__action{
display: flex;
align-items: center;
text-decoration: underline;
color: $Tufts-Blue;
font-size: 14px;
font-weight: 600;
.arrow{
    display: flex;
}
}
.settings-card.guardian{
    cursor:default;
}
.navlink.navlink.guardian{
    color: $Tufts-Blue!important;
}
.guardian__toggle{
    margin-top: 10px;
}
.guardian__svg__header{
    display: flex;
    justify-content: space-between;
    span{
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 10px;
        line-height: 20px;
        text-transform: uppercase;
        color:$Tufts-Blue;
        font-weight: bold;
        svg{
            font-size: 16px!important;
        }
    }
}
.payment__toggle{
    display: flex;
    align-items: flex-start;
}
.payment__toggle__status{
    font-size: 14px;
    line-height: 20px;
    color: $title-charcoal;
    font-weight: 600;
}