@import "../colors/colors";
@import "../mixins/index";

.library-container {
  flex-grow: 1 !important;
  background-color: #fff !important;
  min-height: 100vh !important;
}

.create-new-btn {
  height: 36px !important;
  width: 130px !important;
  background-color: #0062A0 !important;
  color: #fff !important;
  font-weight: 900 !important;
  border-radius: 4px !important;
  float: right !important;
  margin-right: 5px !important;
  text-transform: none !important;
}
.create-new-btn span {
  @include text(#fff, 14px, 500, 20px, normal);
}

.options {
  height: 36px !important;
  width: 100px !important;
  background-color: #fff !important;
  color: #757575 !important;
  border-radius: 4px !important;
  float: right !important;
  border: 2px solid #D5D5D5 !important;
}
.options span {
  @include text(#757575, 14px, 600, 20px, normal);
}

.date-placeholder-comment {
  display: block;
  font-size: 14px !important;
  color: $title-charcoal;
  letter-spacing: 0;
  line-height: 20px;
  width: 180px;
}
@media (max-width: 1200px) {
  /* Adjust margin-right when viewport width is below 1200px */
  .create-new-btn {
   margin-top: 10px !important;
  }
  .options{
    margin-top: 10px !important;
  }
}

@media (max-width: 1200px) {
  /* Adjust margin-right when viewport width is below 1200px */
  .date-placeholder-comment {
    display: block;
    font-size: 14px !important;
    color: $title-charcoal;
    letter-spacing: 0;
    line-height: 20px;
    margin-right: 100px;
  }
}

.cl-table.MuiPaper-elevation.MuiPaper-rounded {
  max-width: 100% !important;
  height: 100% !important;
}

.dialog-content-comment-library {
  margin-top: 2px;
  padding-left: 40px !important;
  padding-right: 28px !important;
  padding-bottom: 5px !important;

  .input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 8px;

    span {
      color: $severity-Electric-Red;
    }
  }

  .comment-input-form-label {
    @include text($Sonic-Silver, 13px, 400, 16px, normal);
    margin-bottom: 6px;
  }
}

.tab_panel_cLibrary .MuiBox-root {
  padding: 0px !important;
}

.tab_panel_cLibrary {
  // min-height: 400px !important;
  // max-height: 600px !important;
}

.comment-library-action {
  padding-left: 34px !important;
  padding-right: 28px !important;

  .cl-cancel-btn {
    width: 101px !important;
    height: 34px !important;
    @include text($Spanish-Gray, 13px, 600, 24px, normal);
    border: 1px solid $button-border;
    border-radius: 1px !important;
    text-transform: capitalize !important;
    letter-spacing: 0.5px !important;
  }

  .cl-add-btn {
    width: 170px !important;
    height: 34px !important;
    background: $Tufts-Blue !important;
    @include text($secondary-white, 13px, 600, 24px, normal);
    border: 1px solid $Tufts-Blue;
    border-radius: 1px !important;
    text-transform: unset !important;
    letter-spacing: 0.5px !important;
  }
  .cl-add-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
    border: 1px solid $secondary-Smoke-White;
  }
}

.cl-sort-icon {
  font-size: 23px !important;
  color: $Santa-Grey !important;
}

.cl-table-head,
.cl-table-head span {
  @include text($Outer-Space, 14px, 600, 20px, normal);
}

.styled-tab,
.styled-tab span {
  @include text(inherit, 14px, 600, 20px, normal);
}

.cl-table-exp-icon {
  rotate: 90deg;
  color: $Mountain-Mist;
  margin-left: 15px;
}

.cl-row-status {
  button {
    padding: 0px !important;
  }
}

.cl-search {
  position: relative;

  .MuiOutlinedInput-root {
    padding-left: 26px !important;
    border-radius: 4px !important;
  }

  button {
    padding-right: 0px;
  }

  background-color: $secondary-white;

  & > div:first-child {
    width: 100%;
  }

  .cl-search-icon {
    position: absolute;
    left: 10px;
    top: 12px;
    cursor: pointer;
    font-size: 18px !important;
  }
}

.cl-search-container {
  padding: 20px 0px 10px 0px !important;
}

.cl-title-col {
  width: 142px !important;
  @include text($Outer-Space, 12px, 400, 20px, normal);
  vertical-align: text-top !important;
}

.cl-comment-col {
  width: 300px !important;
  vertical-align: text-top !important;
}
.cl-summary-col {
  width: 280px !important;
  vertical-align: text-top !important;
}
.cl_rating_square_container {
  max-width: 126px !important;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  border-radius: 4px;
  .rating_square {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    font-size: 8px;
    color: $secondary-white;
  }

  .cl_rating_name_text {
    @include text($Outer-Space, 12px, 600, 15px, normal);
  }
}
.cl-rating-col {
  min-width: 165px !important;
  vertical-align: text-top !important;
}
.cl-exp-col {
  padding-top: 16px !important;
  vertical-align: text-top !important;
}
.cl-checkbox-col {
  vertical-align: text-top !important;
}
.cl-checkbox-col.cl-check-active {
  border-left: 1px solid $Tufts-Blue;
}
.cl-comment-col,
.cl-summary-colS {
  position: relative;
}
.cl-global-col {
  width: 200px !important;
  vertical-align: text-top !important;
  svg {
    color: $Sonic-Silver !important;
  }
}
@media (min-width: 1024px) {
  .cl-comment-col {
    width: 650px !important;
    vertical-align: text-top !important;
  }
  .cl-summary-col {
    width: 500px !important;
  }
  .cl-rating-col {
    min-width: 170px !important;
    vertical-align: text-top !important;
  }
}

.comment-text {
  @include text($text-Black-Olive, 14px, 400, 22px, normal);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.comment-text.expanded {
  overflow: visible;
  display: block;
}

.comment-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.comment-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.comment-text.expanded::after {
  display: none;
}
.loader-container-comment-library {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 425px !important;
  max-height: 600px;
}
.comment-text select {
  height: 22px;
  appearance: none;
  padding: 2px 6px !important;
  border: 1px solid #d7d7d7 !important ;
  background: #f7f7f7;
  border-radius: 4px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 200px;
  min-width: 100px;
}
.comment-text select option,
.narrative_summary_content select option,
.comment_description_cl select option {
  background-color: #fff !important;
}
.comment-text select option:hover {
  background-color: #000 !important;
}
.comment-text.expanded select,
.narrative_summary_content select,
.comment_description_cl select {
  background: $Cyan-Blue;
  appearance: none;
  height: 28px;
  padding: 2px 14px 2px 8px !important;
  border-radius: 4px;
  margin: 0px 2px;
  border: 1px solid $Tufts-Blue;
  background-image: url("../../images/arrowdown.png");
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 10px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 200px;
  min-width: 100px;
}

.comment_library_upload svg {
  margin-right: 10px;
  font-size: 16px;
}

.comment_library_upload {
  border: 1px solid $primary-Sonic-Silver !important;
  color: $primary-Sonic-Silver !important;
  text-transform: none !important;
}