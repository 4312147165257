@import "../colors/colors";
@import "../mixins/index";
.cl-dropdown {
  width: 140px !important;
  height: 30px;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0px 2px;
  border: 1px solid #d9d9d9;
} 
