@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_fonts.scss";
@import "../mixins/_styles.scss";

.isFlex {
  display: flex;
}
.isFlexCentreAlign {
  display: flex;
  align-items: center;
  gap: 7px;
}
.line-header {
  @include text($text-Black-Olive, 24px, 400, 28px, 0);
  margin-bottom: 16px !important;
  margin-top: 50px;
}
.line-sub-head {
  @include text($primary-Sonic-Silver, 12px, 400, 21px, 0);
  margin-bottom: 7px !important;
  text-transform: uppercase !important;
}
.template-image-wrapper {
  display: flex;
  gap: 15px;
  img {
    height: 154px;
    width: 193px;
    margin-bottom: 10px;
  }
}
.img_btn {
  @include text($text-Black-Olive, 13px, 600, 24px, 0);
  button {
    @include text($text-Black-Olive, 13px, 600, 24px, 0.5px);
    border: 1px solid $Mountain-Mist;
    border-radius: 2px;
    padding: 12px 35px;
    margin-top: 30px;
    &:hover {
      border: 1px solid $Mountain-Mist;
      border-radius: 2px;
    }
  }
}
.btn-desc {
  @include text($text-Black-Olive, 12px, 400, 20px, 0);
  margin-top: 12px !important;
}

// Location
.location-input {
  svg {
    font-size: 18px;
    color: $Tufts-Blue;
    margin-right: 6px;
  }
  .edit-text {
    @include text($Tufts-Blue, 14px, 400, 20px, 0);
  }
}
.location-input-text {
  display: flex;
  align-items: center;
  padding-left: 15px;
  min-height: 41px;
  border: 1px solid $Platinum;
  width: 100%;
  flex-wrap: wrap !important;
  border-radius: 4px;
  margin-right: 15px;
}
.input-dialog {
  padding: 5px 28px 5px 24px;
  p:first-child {
    margin-bottom: 7px;
  }
}
.location-display-word {
  padding: 2px 4px;
  &:hover {
    background: #c4d7e9;
    padding: 2px 4px;
    cursor: pointer;
    border-radius: 3px;
    &:after {
      content: " x";
      padding: 2px 4px;
    }
  }
}
.dialog-container-location h2 {
  font-size: 22px !important;
}
.dialog-content-location {
  margin-top: 2px;
  padding: 20px 0px 20px 24px !important;
  .input-form-label {
    color: $primary-Sonic-Silver;
    opacity: 57%;
    span {
      color: $severity-Electric-Red;
    }
  }
}
.dialog-container-location .MuiPaper-elevation.MuiPaper-rounded {
  max-height: calc(100% - 20px) !important;
  max-width: 730px !important;
  padding: 9px 0px 10px 9px;
}
.location-edit-btn {
  @include text($secondary-black, 13px, 600, 24px, 0.5px);
  margin-top: 15px !important;
  margin-left: 25px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  height: 34px !important;
  padding: 5px 24px !important;
  svg {
    @include text($secondary-black, 17px, 600, 0px, 0px);
  }
}
.dialog-action-location {
  margin-bottom: 15px;
  margin-right: 20px;
  button {
    margin-top: 15px;
    color: $secondary-white;
  }
}
.loc-item {
  max-width: 205px;
  height: 30px;
  max-height: 30px;
  border: 1px solid $Tufts-Blue;
  border-radius: 3px;
  margin-bottom: 9px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif !important;
  @include text($Tufts-Blue, 12px, 600, 16px, 0px);
  &:hover {
    background: $Cyan-Blue;
    cursor: pointer;
    transition: background-color 100ms linear;
  }
}
.edit-loc-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
  max-width: 194px;
  margin-bottom: 8px;
  input {
    max-width: 146px;
    margin-right: 3px;
    height: 32px !important;
    border: 1px solid $title-graphite;
    border-radius: 3px;
    padding: 0px 10px;
  }
  .drag-handle-icon {
    color: $title-graphite;
    opacity: 0.7;
  }
  .loc-delete-icon {
    color: $title-graphite;
    opacity: 0.7;
    &:hover {
      color: $severity-Electric-Red;
      transition: color 150ms linear;
      cursor: pointer;
    }
  }
}
.add-cta {
  color: $Tufts-Blue;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  span {
    display: flex;
    gap: 7px;
  }
  svg {
    font-size: 18px;
  }
}
// sun editor styles
.sun-editor {
  border-radius: 3px;
  .se-btn-tray {
    background: $secondary-white;
  }
  .se-toolbar {
    border-radius: 3px;
  }
  .se-resizing-bar {
    display: none !important;
  }
  .se-menu-list {
    button {
      height: 28px;
    }
    li:nth-child(-n + 5) {
      margin: 0px 7px;
      button {
        background: $Lime-Gray;
      }
    }
    .active {
      background: $secondary-Tufts-Blue !important;
      color: $secondary-white !important;
    }
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
      padding-right: 16px;
      border-right: 1px solid $border-grey;
    }
    li:last-child {
      button {
        display: flex;
        align-items: center;
      }
    }
  }
}
.template-rating-select {
  div {
    height: 40px;
  }
}
.comment-section-wrapper {
  margin-bottom: 21px;
}
@media only screen and (max-width: 600px) {
  .dialog-container-location {
    padding: 9px 8px 30px 2px;
  }
  .location-edit-btn {
    @include text($secondary-black, 13px, 600, 24px, 0.5px);
    margin-top: 15px !important;
    margin-left: 10px !important;
    white-space: pre;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    height: 34px !important;
    padding: 5px 10px !important;
    svg {
      @include text($secondary-black, 17px, 600, 0px, 0px);
    }
  }
}

.text {
  @include text($primary-Sonic-Silver, 12px, 400, 21px, 0);
  // text-transform: uppercase !important;
  border: none !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  width: 500px;
}

.textDisable {
  @include text(#979797, 12px, 400, 21px, 0);
  text-transform: uppercase;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  width: 500px;
}
.text textarea:focus,
input:focus {
  outline: none;
}

@media only screen and (max-width: 680px) {
  .text {
    width: 300px !important;
  }
  .textDisable {
    width: 300px !important;
  }
}

@media (min-width: 895px) and (max-width: 1200px) {
  .text {
    width: 200px !important;
  }
  .textDisable {
    width: 200px !important;
  }
}

.new-comment {
  background-color: $Branding-primary-blue !important;
  color: $secondary-white !important;
  width: 162px !important;
  height: 36px !important;
  padding: 8px 16px 8px 12px !important;
  gap: 4px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  box-shadow: none !important;
  min-width: 158px !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.tagAutoComplete {
  .MuiButtonBase-root:nth-child(1) {
    margin-left: 8px !important;
  }

  .customSearch {
    position: absolute;
    left: 5px;
  }
  .customSearch.hidden {
    display: none !important;
  }

  .MuiCheckbox-root input {
    border-radius: 5px !important;
  }

  .customArrowDown {
    position: absolute;
    right: 12px;
    color: #637083;
    cursor: pointer !important;
    top: 13px !important;
    font-size: 14px !important;
  }
  .customArrowDown.arrowActive {
    rotate: 180deg;
  }

  input:hover {
    cursor: pointer !important;
  }
  .MuiButtonBase-root {
    background-color: $Tufts-Blue;
    opacity: 0.7;
    color: $secondary-white !important;
  }
  .MuiButtonBase-root:hover {
    opacity: 0.9;
    cursor: pointer;
    color: $secondary-white !important;
  }
  .MuiChip-deleteIcon {
    color: $secondary-white !important;
    background-color: #07439d !important;
    border-radius: 50%;
    font-size: 16px !important;
    padding: 2px !important;
    margin-right: 4px !important;
  }
  .Mui-checked {
    color: $Tufts-Blue !important;
  }
}

.customArrowDown {
  position: absolute;
  right: 12px;
  color: #637083;
  cursor: pointer !important;
  top: 13px !important;
  font-size: 14px !important;
}

.customArrowDown.arrowActive {
  rotate: 180deg;
}

.editor-class {
  background-color: #fff;
  padding: 1rem;
  border-top: 1px solid #ccc;
  min-height: 440px;
  font-size: 14px;
  color: $Dark-Charcoal2;
}

.comment-title-text {
  @include text($primary-Sonic-Silver, 12px, 400, 21px, 0);
  margin-bottom: 7px !important;
  margin-top: 20px !important;
  text-transform: uppercase !important;
}

.comment-dialog-non-narrative .MuiPaper-elevation.MuiPaper-rounded {
  max-width: 1200px !important;
  width: 100% !important;
  max-height: 760px !important;
  height: 100% !important;
}

.comment-dialog-non-narrative h2 {
  padding: 16px 24px 0px 24px !important;
  color: $Dark !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 32px !important;
}

.non-narrative-comment-title {
  padding: 16px 0px 0px 0px !important;
  text-align: center;
  color: $Dark !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.close-wrapper-new-category-right {
  svg {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
    color: $text-Black-Olive !important;
  }
}

.non-narrative-comment-tabs {
  min-height: 40px;
  height: 40px;
}

.non-narrative-comment-tab {
  color: #4282e2 !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  text-transform: none !important;
}

.non-narrative-comment-label {
  color: $Outer-Space !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  margin-bottom: 6px !important;
  opacity: 0.5671 !important;
}

.non-narrative-comment-cancel {
  color: $Outer-Space !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  display: flex !important;
  height: 36px !important;
  padding: 8px 16px 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2px !important;
  border: 1px solid $border-grey !important;
  background: $secondary-white !important;
  text-transform: none !important;
}

.non-narrative-comment-save {
  display: flex !important;
  height: 36px !important;
  padding: 8px 16px !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 2px !important;
  background: $secondary-Tufts-Blue !important;
  color: $secondary-white !important;
  font-feature-settings: "liga" off, "clig" off !important;
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.non-narrative-comment-save:disabled {
  cursor: not-allowed;
  background-color: $secondary-Smoke-White !important;
  border: 1px solid $secondary-Smoke-White;
}

.non-narrative-dialog-actions {
  display: flex !important;
  gap: 4px !important;
  margin: 10px !important;
}
