// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
.schedule-inspection-container {
    display: flex;
    background: grey;
    padding: 120px 0px;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}
.schedule-inspection-container-light {
    background: grey;
    height: 100%;
}
.inspection-data-modal {
    padding: 70px 55px 30px 55px;
    background: white;
    border-radius: 5px;
    .MuiStepConnector-line {
        border-top-width: 2px!important;
    }
}
.inspection-data-modal-light {
    padding: 70px 5px 20px 5px;
    background: white;
}
.modal-title {
    position: absolute;
    top: 33px;
    font-size: 22px !important;
    left: 33px;
    margin-bottom: 38px;
    color: $title-charcoal;
}
.modal-title-light {
    font-size: 22px !important;
    margin-bottom: 38px;
    text-align: center;
}
.forms-control {
    width: 100% !important;
    margin-bottom: 25px !important;
    padding: 0px 15px !important;
}
.add-agent-form {
    width: 100% !important;
    padding: 0px 15px !important;
}


.buyer2{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.is-flex {
    display: flex;
    justify-content: space-between;
    p {
        margin-right: 10px;
    }
}
.modal-wrapper {
    min-height: 150px;
}
.modal-container {
    position: relative;
    max-width: 970px !important;
    border: 1px solid $secondary-platinum;
    border-radius: 5px;
    background-color: $secondary-white;
}
.close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    svg {
        color: $text-Black-Olive;
    }
}
.input-form-label {
    font-size: 12px !important;
    top: 17px !important;
    font-family: "Open Sans" !important;
    letter-spacing: 0 !important;
    color: $primary-Sonic-Silver;
    line-height: 21px !important;
    text-transform: uppercase !important;
    margin-bottom: 5px;
}
.input-form-label-select {
    font-size: 12px !important;
    top: 45px !important;
    font-family: "Open Sans" !important;
    letter-spacing: 0 !important;
    color: $secondary-Taupe-Gray;
    line-height: 21px !important;
    text-transform: uppercase !important;
    display: inline-block;
    margin-bottom: 5px;
}
.pl-15 {
    padding-left: 15px;
}
.input-textfield {
    font-family: "Open Sans";
    letter-spacing: 0;
}
.disabled-input {
    background: $Cultured !important;
    border-color: $secondary-Silver-Sand !important;
    color: $text-Black-Olive !important;
}
.button-section {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
}
.button-section-light {
    padding: 0px 15px;
}
.cancel-btn-wrapper {
    justify-content: center;
    display: flex;
    button {
        margin-top: 20px;
    }
}
.disable-btn-wrapper {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// stepper styles
.step-label span {
    color: $secondary-black !important ;
    font-size: 14px !important;
    font-family: "Open Sans" !important;
    font-weight: 400!important;
}
.step-label span svg {
    color: $secondary-black !important ;
    width: 24px !important;
    height: 24px !important;
}
.step-label span svg text {
    font-size: 14px !important;
    font-weight: normal;
}
.step-label span svg.Mui-active {
    color: $primary-steeper-Green !important ;
}
.step-label .MuiStepLabel-labelContainer .Mui-active {
    color: $title-charcoal !important;
}
.MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    color: $secondary-Gray !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
//styling mui inbuild classes
.custom-color .MuiButtonBase-root.MuiChip-root {
    border-radius: 3px !important;
    color: $Green-Crayola !important;
    border: 1px solid $Green-Crayola !important;
    background-color: transparent !important;
    position: relative;
    height: 30px!important;
    &::after {
        content: "";
        border-left: 1px solid $Green-Crayola;
        height: 28px;
        position: absolute;
        right: 26px;
    }
}
.custom-color .MuiChip-label {
    margin-right: 9px !important;
    color: $Green-Crayola !important;
}
.custom-color .MuiSvgIcon-root.MuiChip-deleteIcon {
    color: $Green-Crayola !important;
    font-size: 14px;
    margin-right: 6px;
}
.custom-color .MuiAutocomplete-clearIndicator {
    svg {
        font-size: 14px !important;
    }
}
.custom-color .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
    padding-top: 5px!important;
    // height: 40px!important;
}
.input-controll{
    input{
        height: 23px!important;
    }
}
// Client details styles
.clients-controll {
    padding: 0px 37px;
    .client-title {
        padding: 0px 16px;
        font-size: 18px;
        margin-bottom: 11px;
        color: $text-Black-Olive;
    }
   
}
.modal-sub-title {
    p:first-child {
        font-size: 18px !important;
        font-family: "Open Sans" !important;
        padding-left: 15px;
        color:$text-Black-Olive;
    }
    p:last-child {
        font-size: 14px !important;
        font-family: "Open Sans" !important;
        margin-bottom: 15px;
        padding-left: 15px;
    }
}
.modal-sub-title2 {
    margin-top: 45px!important;
    margin-bottom: 16px!important;
    p {
        display: flex;
        align-items: center;
        font-size: 18px !important;
        font-family: "Open Sans" !important;
        padding-left: 15px;
        color:$text-Black-Olive;
        span {
            display: flex;
            align-items: center;
            font-weight: 600;
            color: $severity-Electric-Red;
            cursor: pointer;
            svg {
                margin-left: 14px;
                margin-right: 5px;
                color: $severity-Electric-Red;
            }
        }
    }
}
.bottom-spacing {
    margin-bottom: 25px !important;
}
.add-client {
    margin-bottom: 10px !important;
    p {
        font-size: 14px;
        font-family: "Open Sans" !important;
        font-weight: 600;
        color: $secondary-Tufts-Blue;
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        svg {
            width: 15px;
            height: 15px;
        }
    }
}
.multi-select{
    .MuiSelect-select.MuiSelect-outlined{
        height: 23px!important;
    }
}
// Agent details styles
.modal-inner-sub-title {
    padding: 0px 15px;
    p {
        font-size: 18px !important;
        font-family: "Open Sans" !important;
        margin: 25px 0px 10px 0px;
        color: $text-Black-Olive;
    }
    table td {
        color: $title-charcoal !important;
        font-family: "Open Sans" !important;
        font-size: 14px !important;
        border-color: $secondary-Silver-Sand !important ;
    }
    table tr:last-child td {
        font-weight: bold !important;
    }
}
.add-select {
    color: $primary-Jungle-Green !important;
    padding: 10px 16px !important;
    font-size: 14px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: left !important;
}
// Service style
.add-action {
    margin-bottom: 10px !important;
    p {
        margin-top: 12px;
        margin-left: 3px;
        font-size: 14px;
        font-family: "Open Sans" !important;
        font-weight: 600;
        color: $secondary-Tufts-Blue;
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        svg {
            margin-left: 5px;
            width: 15px;
            height: 15px;
        }
    }
}
.discount-container {
    margin-top: 15px;
}
.services-action-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .edit-service,
    .delete-service {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            font-size: 14px;
            margin-right: 5px;
        }
        span {
            font-size: 12px;
            font-weight: 600;
        }
    }
}
.edit-service {
    color: $secondary-Tufts-Blue;
    margin-right: 13px;
}
.delete-service {
    color: $severity-Electric-Red;
}
.agent-list{
    color: $text-Black-Olive !important;
}
// schedule inspection & submit inspection message screens
.inspection-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $title-charcoal;
    p:first-child {
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: center;
        svg {
            width: 52px;
            height: 52px;
            color: $primary-Jungle-Green;
        }
    }
    p:nth-child(2) {
        font-size: 22px;
        font-family: "Open Sans" !important;
        margin-bottom: 5px;
        text-align: center;
    }
    p:last-child {
        font-size: 14px;
        font-family: "Open Sans" !important;
        text-align: center;
        max-width: 570px;
        line-height: 20px;
        color: $title-charcoal;
        margin-bottom: 110px;
    }
}
.inspection-message-action {
    text-align: right;
    button {
        color: $secondary-white;
    }
}

// button styles
button {
    text-transform: capitalize !important;
    font-family: "Open Sans" !important;
    font-weight: 600;
}
.default-btn {
    background: $Tufts-Blue !important;
    width: 121px !important;
    height: 34px !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    font-weight: 600 !important;
}
.default-btn:disabled {
    cursor: not-allowed;
    background-color: $secondary-Smoke-White !important;
  }
.default-btn-light {
    background: $primary-Jungle-Green !important;
    width: 100% !important;
    height: 48px !important;
    font-size: 16px !important;
    border-radius: 2px !important;
}
.back-btn {
    width: 101px !important;
    height: 34px !important;
    font-weight: 700 !important;
    border: 1px solid $Spanish-Gray !important;
    color: $primary-Sonic-Silver !important;
    border-radius: 2px !important;
    // &:hover {
    //     background: $Tufts-Blue !important;
    //     color: $secondary-white !important;
    // }
    p {
        font-family: "Open Sans", sans-serif !important;
        font-size: 13px !important;
        font-weight: 600;
    }
}
.back-btn-disabled {
    width: 110px !important;
    height: 34px !important;
    font-size: 13px !important;
    color: $primary-Sonic-Silver !important;
    border-radius: 2px !important;
    visibility: hidden;
    p {
        font-family: "Open Sans", sans-serif !important;
        font-size: 13px !important;
        font-weight: 600;
    }
}
.back-btn-content {
    display: flex;
    align-items: center;
    svg {
        height: 12px;
    }
}
.xs-btn {
    height: 48px !important;
    width: 90% !important;
}
.cancel-btn {
    color: $primary-Sonic-Silver !important;
    width: 75px !important;
    height: 34px !important;
    border-radius: 2px !important;
    margin-right: 15px !important;
    &:hover {
        background: transparent !important;
    }
    p {
        font-family: "Open Sans", sans-serif !important;
        font-size: 13px !important;
        border-bottom: 1px solid $primary-Sonic-Silver !important;
        padding: 0px 3px;
        font-weight: 600;
    }
}
.draft-btn {
    margin: 0px 8px !important;
    text-transform: none !important
}
.add-btn {
    background: $primary-Jungle-Green !important;
    border-radius: 2px !important;
    margin-left: 13px;
    margin-bottom: 3px;
    height: 34px!important;
    p {
        font-family: "Open Sans", sans-serif !important;
        font-weight: 600 !important;
        font-size: 13px !important;
    }
}
.clear-btn {
    background: $primary-Sonic-Silver !important;
    border-radius: 2px !important;
    margin-left: 13px !important;
    height: 34px!important;
    p {
        font-family: "Open Sans", sans-serif !important;
        font-weight: 600 !important;
        font-size: 13px !important;
    }
}
.disabled-btn {
    color: $secondary-white !important;
    background-color: #bebebe !important;
    font-weight: 600 !important;
    font-size: 13px !important;
}
// snackbar
.snackbar{
    .MuiAlert-icon{
        display: none;
    }
    div{
        background: $Dark-Charcoal !important;
        color: #fff!important;
        max-width: 350px;
        font-size: 14px;
    }
    .MuiAlert-action{
        svg{
           font-size: 22px; 
        }
       
    }
}
// =========================================================================

// changing scrollbar style for chrome only applied to chrome
// ::-webkit-scrollbar {
//     background-color:$secondary-white;
//     width:16px
// }
// ::-webkit-scrollbar-thumb {
//     background-color:#babac0;
//     border-radius:16px;
//     border:5px solid $secondary-white
// }
// ::-webkit-scrollbar-thumb:hover {
//     background-color:#a0a0a5;
//     border:4px solid #f4f4f4
// }

// media query to hide step label for mobile screens
@media only screen and (max-width: 600px) {
    .step-label span + span {
        display: none !important;
    }
}

@media only screen and (max-width: 1440px) {
    .inspection-data-modal {
        width:970px !important;
    }
}
