$asset-font-path: "../../fonts/";

$OpenSans_Regular_Filename: "OpenSans-Regular.ttf";
$OpenSans_Bold_Filename: "OpenSans-Bold.ttf";
$OpenSans_SemiBold_Filename: "OpenSans-SemiBold.ttf";
$OpenSans_Medium_Filename: "OpenSans-Medium.ttf";
$Roboto_Regular_Filename: "Roboto-Regular.ttf";

$OpenSans_Regular: "OpenSans-Regular";
$OpenSans_Bold: "OpenSans-Bold";
$OpenSans_SemiBold: "OpenSans-SemiBold";
$OpenSans_Medium: "OpenSans-Medium";
$Roboto_Regular: "Roboto-Regular";

@font-face {
  font-family: $Roboto_Regular;
  src: url($asset-font-path + $Roboto_Regular_Filename) format("opentype");
}

@font-face {
  font-family: $OpenSans_Regular;
  src: url($asset-font-path + $OpenSans_Regular_Filename) format("opentype");
}

@font-face {
  font-family: $OpenSans_Bold;
  src: url($asset-font-path + $OpenSans_Bold_Filename) format("opentype");
}

@font-face {
  font-family: $OpenSans_SemiBold;
  src: url($asset-font-path + $OpenSans_SemiBold_Filename) format("opentype");
}

@font-face {
  font-family: $OpenSans_Medium;
  src: url($asset-font-path + $OpenSans_Medium_Filename) format("opentype");
}
@font-face {
  font-family: 'Source Sans Pro', sans-serif;
  src: url("../../fonts/SourceSansPro-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro', sans-serif;
  src: url("../../fonts/SourceSansPro-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Sans Pro', sans-serif;
  src: url("../../fonts/SourceSansPro-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.font-13 {
  font-size: 13px;
}
