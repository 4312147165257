// imports
@import "../colors/colors";
@import "../mixins/padding";

// font import
@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/OpenSans-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans 3" !important;
  src: url("../../fonts/SourceSans3-VariableFont_wght.ttf") format("opentype");
  font-weight: 400 !important;
  font-optical-sizing: auto;
  font-style: normal;
}

@import url(http://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html,
body,
html * {
  font-family: "Roboto", sans-serif;
}

// global font
.global-font {
  font-family: "Open Sans" !important;
}
// custom styles
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  z-index: 99;
  button:hover {
    background-color: transparent;
  }
}
.navbar {
  background-color: $primary-Jungle-Green !important;
}
.main-logo {
  margin-top: 6px !important;
  margin-bottom: 7px !important;
}
.verical-logo {
  height: 45px;
  width: 95px;
}
.horizontal-logo {
  height: 25px;
  width: 171px;
}
.header-search-wrapper {
  display: block;
}
.navbar .header-search-bar,
.navitems .header-search-bar {
  color: $secondary-white;
  background-color: $transparant-black !important;
  margin-right: 44px;
  &:hover {
    background-color: $transparant-black !important;
  }
}
.navbar .header-search-bar div + div {
  height: 40px !important;
  width: 216px !important;
}
.header-search-bar-light {
  color: $text-Black-Olive !important;
  border: 1px solid $secondary-Gray;
  margin-right: 4px;
  .css-1iz4iln {
    padding: 0px 11px !important;
  }
  div {
    height: 40px !important;
  }
  div + div input {
    padding: 8px 0px !important;
    padding-left: calc(1em + 26px) !important;
  }
}
.navbar .notification-badge svg {
  width: 32px !important;
  height: 29px !important;
  // font-family: "Open Sans" !important;
  color: $secondary-white;
}
.navbar .notification-badge span {
  transform: scale(1) translate(-90%, 0%) !important;
  width: 18px !important;
  height: 18px !important;
  // font-family: "Open Sans" !important;
}
.profile-icon-wrapper div {
  width: 35px !important;
  height: 35px !important;
}
.profile-icon-wrapper > svg {
  color: $secondary-white;
}
.navbar-profile-icon {
  font-size: 23px;
  color: $secondary-black;
}
.user-name {
  margin-left: 12px !important;
  font-size: 12px !important;
  color: $secondary-white;
}
.user-name-light {
  margin-left: 12px !important;
  font-size: 14px !important;
  color: $secondary-white;
}
.profile-menuitem ul li {
  font-size: 13px;
  padding: 15px 40px 15px 15px !important;
}
//bottom navbar styles
.navitems {
  box-shadow: none !important;
  border-bottom: 1px solid $secondary-Gray;
  background: $secondary-white !important;
}
.navmenu-icon {
  color: $primary-Jungle-Green;
  margin: 0px 12px 0px 0px;
  font-size: 37px !important;
  border-radius: 5px;
  width: 45px;
  height: 34px;
}
.display-toggle {
  display: none;
}
.horizontal-menus {
  display: flex;
}
.navlink {
  display: flex;
  p {
    font-size: 14px;
    font-weight: 600 !important;
  }
}
.revenue {
  display: flex !important;
}
.nav-button {
  padding: 6px 0px !important;
  margin: 0px !important;
  &:hover {
    background: none !important;
  }
}
.mobile-menus {
  color: $text-Black-Olive !important;
  text-transform: capitalize;
}
.navlist {
  color: $text-Black-Olive;
  font-weight: 600 !important;
  text-transform: capitalize;
  border-bottom: 2px solid $secondary-white;
  padding: 5px 8px;
  &:hover {
    border-bottom: 2px solid $primary-Jungle-Green;
    color: $primary-Jungle-Green;
  }
}
.active-link {
  border-bottom: 2px solid $primary-Jungle-Green;
}
.nav-inspection {
  color: $primary-Jungle-Green;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-size: 13px !important;
}
.nav-inspection-light {
  color: $text-Black-Olive;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-size: 16px !important;
  border-bottom: 2px solid $primary-Jungle-Green;
  padding: 3px 5px;
}
.nav-down-arrow {
  color: $primary-Jungle-Green;
  font-size: 24px !important;
  margin-left: 5px;
}
.dropdown-menu {
  color: $text-Black-Olive !important;
  font-weight: 600;
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.chart-icon {
  color: $text-Black-Olive;
  margin: 0px 4px;
  padding: 6px 5px;
  font-size: 3.5em !important;
}
.revenue-content {
  font-size: 13px !important;
  color: $text-Black-Olive;
  text-transform: initial;
  text-align: left;
}
.revenue-amount {
  font-size: 14px !important;
  color: $primary-Jungle-Green;
  font-weight: 600;
}

.home-breadcrumb {
  cursor: pointer;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: $Branding-primary-blue !important;
}
.breadcrumb-last {
  text-transform: capitalize;
  color: $primary-Sonic-Silver !important;
  cursor: pointer;
  font-size: 12px !important;
}
.breadcrumb-normal {
  color: $title-charcoal !important;
  font-size: 12px !important;
  text-decoration: none !important;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    text-decoration: underline !important;
  }
}
.stack-container {
  background-color: $mist-bleach;
  padding: 4px 0px 4px 15px;
  font-weight: 700;
  border-radius: 5px;
}
.breadcrumb-container {
  padding: 40px 30px 10px 30px;
  font-family: "Source Sans 3";
  margin-top: 40px;
  border-radius: 4px !important;
  .MuiBreadcrumbs-separator {
    color: $secondary-grey;
    font-size: 16px;
    margin-bottom: 1px;
  }
}
.breadcrumb-container-with-alert {
  padding: 20px 30px;
  .MuiBreadcrumbs-separator {
    color: $secondary-grey;
    font-size: 16px;
    margin-bottom: 1px;
  }
}
// media query to fit bell icon in a line in header for mobile screens
@media only screen and (max-width: 380px) {
  .notification-icon {
    padding: 3px !important;
  }
  .profile-icon-wrapper {
    padding: 3px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .revenue {
    display: none !important;
  }
}
@media only screen and (max-width: 900px) {
  .breadcrumb-container {
    padding: 20px 23px;
  }
}
// new breakpoint added for tablet view
@media only screen and (max-width: 750px) {
  .header-search-wrapper {
    display: none;
  }
  .display-toggle {
    display: flex;
  }
  .horizontal-menus {
    display: none;
  }
  .breadcrumb-container {
    padding: 20px 23px;
    margin-top: 150px;
  }
}
.page-background {
  background-color: #f5f5f5;
  min-height: 100vh;
}
