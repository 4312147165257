.button_group_container {
  height: 32px;
  // width: 97px;
  width: 70px;
  display: flex;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.button_group_tab {
  width: 36px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 1px solid #d5d5d5;
}

.button_group_tab:hover {
  background-color: #d5d5d5 !important;
}

.button_group_delete {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_group_delete:hover {
  background-color: #d5d5d5 !important;
}
