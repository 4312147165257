// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
// *:not(.editor-class span) {
//     font-family: "Open Sans", sans-serif !important;
// }

.details-card {
    padding: 20px 28px 35px 18px;
}
.notifications-centre-card {
    .cardHeader {
        padding: 14px 16px 8px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        .MuiCardHeader-title {
            color: $text-Black-Olive;
            font-family: "Open Sans", sans-serif !important;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 21px;
        }
        .edit-action {
            text-transform: none;
            color: $primary-Jungle-Green;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            gap: 4px;
            svg {
                font-size: 21px;
            }
        }
    }
    .details-card {
        svg {
            font-size: 24px;
        }
        span {
            color: $title-charcoal;
            font-family: "Open Sans", sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
        }
        label{
            width: 100%;
        }
        .MuiCheckbox-root{
            padding: 0px!important;
            margin: 0px 9px 0px 9px;
        }
    }
}
.mt-80{
    margin-top: 80px!important;
}
