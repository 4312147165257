@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_styles.scss";
@import "../mixins/_fonts.scss";
.importDialogHeading {
  color: #333 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
  padding-top: 20px !important;
}

.dotImage {
  padding-top: 6px !important;
  font-size: 16px !important;
}

.importTitleDesc {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  margin-top: 8px !important;
  margin-left: 32px !important;
}

.optionDialogTitle {
  color: #333 !important;
  text-align: center !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.optionDialogDesc {
  color: #333 !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
}

.importDialogTitle {
  color: #333 !important;
  text-align: center !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 30px !important;
}

.optionDialogTypo {
  color: #333 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: center !important;
}

.optionHeading {
  color: #333 !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.optionButtons {
  border: none !important;
  color: $secondary-white !important;
  border-radius: 4px !important;
  background-color: #0f75bc !important;
  box-shadow: none !important;
  width: 200px !important;
  text-transform: none !important;
  height: 34px !important;
  position: relative !important;
  top: 10px;
  font-weight: 600 !important;
  font-size: 13px !important;
  letter-spacing: 0.04px !important;
  line-height: 24px !important;
  margin-left: 28.8px !important;
  cursor: pointer !important;
  &:hover {
    background-color: #0f75bc !important;
  }
}

.ptTextButton {
  margin-bottom: 8px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-left: 4px !important;
}

.ptDoneIcon {
  color: #4282e2 !important;
  font-size: 28px !important;
}

.ptSuccessTitle {
  font-size: 32px !important;
  font-weight: 400 !important;
}

.ptDialogButton {
  background-color: #0f75bc !important;
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
}

.templatePaperDesign {
  border: 1px grey solid !important;
  padding: 20px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  width: 266px !important;
  height: 83px !important;
  cursor: pointer !important;
}

.templateContactUs {
  color: #5a92e6 !important;
  text-decoration: none !important;
}

.ptDialogButton {
  background-color: #0f75bc !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
}

.ptCancelButton {
  background-color: #ffffff !important;
  color: #9a9a9a !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  text-transform: none !important;
  letter-spacing: 0.04px !important;
  padding-inline: 20px !important;
  border: 1px solid #9a9a9a !important;
}

.uploadBox {
  border: 2px dashed #71a1e9 !important;
  background-color: #f3f8fe !important;
  margin: 20px 0px !important;
  padding: 20px 0px !important;
  cursor: pointer !important;
}

.uploadTitle {
  font-weight: bold !important;
  font-size: 18px !important;
  color: #4d89e4 !important;
}

.ptUploadDesc {
  font-weight: bold !important;
  font-size: 18px !important;
}

.ptFileCard {
  width: 60px !important;
  height: 60px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ptFileIcon {
  font-size: 34px !important;
  color: #0f75bc !important;
}

.ptFileName {
  font-weight: bold !important;
  font-size: 14px !important;
  margin-bottom: 10px !important;
}

.ptFileSize {
  font-size: 10px !important;
  color: #9a9a9a !important;
}

.fileBox {
  background-color: #f3f3f3 !important;
  padding: 16px !important;
  margin-bottom: 16px;
  border-radius: 5px !important;
}

.ptDownloadBtn {
  cursor: pointer !important;
  color: #0f75bc !important;
}
