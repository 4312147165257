// imports
@import "../colors/colors";
@import "../mixins/padding";

.status-container {
    border-radius: 4px;
    padding: 20px 38px 20px 28px;
}
.status_wrapper {
    color: #000;
}
.mb-23 {
    margin-bottom: 23px;
}
.mt-10{
    margin-top: 10px;
}
.default_toggler {
    div {
        display: flex;
        gap: 7px;
        // margin-bottom: 4px;
        align-items: center;
        font-size: 14px;
        color: $title-charcoal;
    }
    p{
        color: $title-graphite;
        font-size: 12px;
        line-height: 20px;
    }
}
.status_chip {
    margin-bottom: 23px;
    span:first-child {
        margin-right: 5px;
    }    
    div{
        display: flex;
        margin-bottom: 7px;
        .status-head{
            color: $secondary-Taupe-Gray;
        }
    }
    .chip_text {
        font-size: 9px;
        text-transform: uppercase;
        padding: 2px 0px;
        font-weight: bold;
        line-height: 13px;
    }
    p{
        font-size: 12px;
        line-height: 20px;
        color: $title-graphite;
    }
}
.attachment,.rating_section{
    p{
        color: $secondary-Taupe-Gray;  
        margin-bottom: 8px;      
    }
}
.attachment_list {
    margin-bottom: 16px;
    .attachment_list_item {
        display: flex;
        justify-content: space-between;
        .doc_title{
            font-size: 14px;
            line-height: 20px;
            color: $text-Black-Olive;
        }
    }
    .list_action {
        display: flex;
        gap: 7px;
    }
    .attachments{
        color: $Tufts-Blue;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 6px;
        svg{
            font-size: 14px;
        }
    }   
}
.add_section{
    color: $Tufts-Blue;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 16px;
    gap: 4px;
    cursor: pointer;
}
.add-icon{
    color: $Tufts-Blue;
    font-size: 17px!important;
}
.rating_section {
    .rating_wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
    }
    .list_action {
        display: flex;
        gap: 7px;
    }
    .rating_square_wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .rating_square {
        display: inline-block;
        min-width: 13px!important;
        width: 13px;
        height: 13px;
        position: relative;
        border-radius: 1px;
        svg {
            position: absolute;
            color: $secondary-white;
            height: 13px;
            width: 13px;
        }
    }
}
.status_actions {
    padding: 50px 20px 0px 30px;
}
.status_cta{    
    margin-bottom: 16px;
}
.temp_editor_cta {
    height: 34px!important;
    width: 100% !important;
    // border-radius: 1px;
    font-size: 13px!important;
    line-height: 24px!important;
    font-weight: 600!important;
    margin-bottom: 8px!important;
    text-transform: none!important;
}
.cta_normal {
    border: 1px solid $Mountain-Mist!important;
    color: $primary-Sonic-Silver !important;
    &:hover{
        background: #0f75bc !important;
        border: 1px solid #0f75bc !important;
        color: $secondary-white!important;
    }    
}
.cta_filled {
    border: 1px solid #0f75bc !important;
    color: $secondary-white !important;
    background: #0f75bc !important; 
    &:hover{
        background: #0f75bc !important;    
    }
}
.last_save_notify{
    text-align: center;
    p:first-child{
        margin-bottom: 10px;
    }
    p{
        line-height: 20px;
        font-size: 12px;
        color: $title-graphite;
    }
}
.dialog-container-template .MuiPaper-elevation.MuiPaper-rounded {
    max-width: 730px!important;
    padding: 9px 28px 10px 9px;
}
.dialog-container-templete h2 {
    font-size: 22px !important;
}
.close-wrapper-template {
    svg {
        position: absolute;
        top: 7px;
        right: 12px;
        cursor: pointer;
    }
}
.dialog-content-template {
    margin-top: 2px;
    padding: 20px 10px 20px 27px !important;
    .input-form-label {
        color: $primary-Sonic-Silver;
        opacity: 57%;
        span {
            color: $severity-Electric-Red;
        }
    }
}
.forms-control-template{
    width: 100% !important;
    margin-bottom: 25px !important;
}
.template-dialog-title{
    font-size: 22px!important;
    line-height: 30px!important;
    color: $title-charcoal;
}
.template-lists{
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}
.default_rating_switch{
    display: flex;
    gap: 10px;
    .rating_switch_svg{
        margin-top: 3px;
    }
    .rating_switch_label{
        span{
            color: $title-charcoal;
            font-size: 14px;
            line-height: 20px;
        }
        p{
            margin-top: 8px;
            font-size: 12px;
            line-height: 20px;
            color: $title-graphite;
        }
    }
}
.summary_checkbox{
    // display: flex;
    // gap: 5px;
    // label{
    //     margin-right: 4px!important;
    // }
    .checkbox_input .MuiCheckbox-root{
        padding: 0px 9px 0px 9px;
    }
    .checkbox_input span{
        // color: $title-charcoal;
        font-size: 14px;
        line-height: 20px;
    }
    .summary_label{
       
        p{
            margin-left: 40px;
            font-size: 12px;
            line-height: 20px;
            color: $title-graphite;
        }
    }
}
.rating_preview{
    display: flex;
    border-left: 1px solid $Mountain-Mist;
    padding: 10px 15px;
    gap: 20px;
    margin-top: 10px;
    .preview_color{
        height: 26px;
        width: 26px;
        border-radius: 2px;
        position: relative;
        svg{
            position: absolute;
            width: 26px;
            height: 26px;
            color: $secondary-white;
        }
    }
    .preview_name{
        font-size: 28px;
        line-height: 20px;
        color: $secondary-Gray;
    }
}
.color-pallets{
    margin-top: 10px;
    max-width: 400px;
    .pallet-wrapper{
        display: inline-flex;
        border: 1px solid #fff;
        align-items: center;
        justify-content: center;
        margin-right: 14px;
        margin-bottom: 3px;
        border-radius: 5px;
        &:hover{
            border: 1px solid $Mountain-Mist;
            border-radius: 5px;
            cursor: pointer;
        }
    }
    .pallet{
        width: 30px;
        height: 30px;
        border-radius: 3px;
        display: inline-block;
        margin: 6px 6px;
    }
}
@media only screen and (max-width: 600px) {
    .dialog-container-template {
        padding: 9px 8px 30px 2px;
    }
    .dialog-content-template {
        padding: 20px 0px !important;
    }
}
@media only screen and (max-width: 950px) {
    .status-container {
        border-radius: 4px;
        padding: 20px 22px 20px 28px;
    }
}

// comment