@import "../colors/colors";
@import "../mixins/index";

.filter-tags-lable,
.filter-tags-lable span {
  @include text($Outer-Grey, 14px, 600, 20px, normal);
}

.filter-tags {
  border-radius: 35px !important;
  border: 1px solid $secondary-Outer-Grey !important;
}

.filter-tags-textfield,
.filter-tags-textfield span {
  @include text($Outer-Grey, 14px, 600, "", normal);
}

.filter-buttongrp-textfield,
.filter-buttongrp-textfield span {
  @include text($Outer-Space, 14px, 600, "", normal);
  margin: 0px 10px 0px 10px;
}

.filter-button-group-sub-container {
  border-right: 1px solid $border-grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding: 0px 10px 0px 10px;
}

.filter-button-group-sub-lastchildcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
}
.filter-button-group-container {
  border: 1px solid $border-grey !important;
  border-radius: 4px !important;
  margin-left: 8px !important;
}

.button-grp-mobile {
  height: 36px !important;
  color: #fff !important;
  border-radius: 4px !important;
  margin-top: 10px !important;
  background-color: #0062A0 !important;
  border-right: 1px solid $border-grey !important;
}
@media (max-width: 1200px) {
  /* Adjust margin-right when viewport width is below 1200px */
  .filter-button-group-container {
    border: 1px solid $border-grey !important;
    border-radius: 4px !important;
    margin-top: 10px !important;
    margin-left: 2px !important;
  }
}
.filter-button-tab {
  height: 36px !important;
  border-right: 1px solid $border-grey !important;
}
.filter-button-tab-last-child {
  height: 36px !important;
}
.filter-button-tab-selected {
  height: 36px !important;
  color: #fff !important;
  border-radius: 4px !important;
  background-color: $Outer-Blue !important;
  border-right: 1px solid $border-grey !important;
}
.filter-buttongrp-selected-textfield,
.filter-buttongrp-selected-textfield span {
  @include text(#fff, 14px, 600, "", normal);
  margin: 0px 10px 0px 10px;
}

::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff !important;
  border-radius: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
  border-radius: 8px !important;
}
