// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
.company-permission-card {
    padding: 26px 16px;
    div:first-child {
        font-size: 20px;
        color: $title-charcoal;
        margin-bottom: 20px;
    }
    p{
        font-size: 16px;
        line-height: 30px;
        color: $title-charcoal;
        font-weight: 600;
    }
    span{
        font-size: 14px;
        line-height: 22px;
    }
    button{
        margin-top: 13px;
    }
    .team-permission-text {
        color: $title-charcoal;
    }
}
.team-avatar{
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.member-name,.member-detail{
    font-size: 14px;
    line-height: 22px;
    color: $title-charcoal;
}
.member-action{
    gap: 17px;
}
.member-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.p-15{
    padding: 0px 15px;
}
.profile-details-cardTitle{
    margin-bottom: 16px;
    padding-left: 33px;
    padding-top: 18px;
    padding-right: 28px;

    span {
        font-size: 20px;
    }
}
.profile-details-cardDetail{
    padding: 10px 42px 10px 33px;
    min-height: 96px;
}
.bottom-border{
    width: 100%;
    border: 1px solid $Bright-Gray;
}
.profile-header{
    padding: 14px 16px 13px 16px !important;
    display: flex;
    justify-content: space-between;
    span:first-child {
        font-size: 20px !important;
        color: $title-charcoal !important;
    }
    .edit-section{
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        span{
           color: $Tufts-Blue!important;
           font-weight: 600; 
        }
    }
    svg {
        font-size: 14px !important;
        color: $text-Black-Olive !important;
    }
    .MuiCardHeader-action {
        margin: 1px 0px 0px 0px !important;
        padding: 0px !important;
    }
}
input{
    height: 23px!important;
}
.teamlist-avatar{
    svg{
        display: none;
    }
}
.map{
    background-color: black;
    color: azure;
}
// padding: 18px 28px 62px 18px;