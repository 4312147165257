@import "././assets/styles/mixins/_fonts.scss";

*,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 13px;
}
*:not(.editor-class span),
html:not(.editor-class span),
body:not(.editor-class span) {
  font-family: $OpenSans_Regular;
}
.app_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh !important;
}
.main__container {
  width: 100%;
  max-width: 1800px;
  overflow: hidden !important;
}
.header-fixed {
  max-width: 1800px;
  margin: auto;
}
