@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_styles.scss";
@import '../mixins/_fonts.scss';

.dashboard_title {
  color: $title-charcoal;
  font-size: 22px;
  word-wrap: break-word;
}

.dashboard_description {
  color: $title-charcoal;
  font-size: 16px;
  line-height: 30px;
}
.page-background {

  background-color: $white-smoke;

  min-height: 100vh;

}
.icon {
  color: $secondary-Smoke-White !important;
  @include widthHeight(22.5px, 25px);
  @include absCenter;
}

// monthly summary section 

.dashboard__summary {
  color: $title-charcoal !important;
  font-size: 22px !important;
  padding: 48px 0 21px 0;

  &__box {
    border-radius: 4px !important;
    padding: 22px 22px 22px 22px !important;
    color: $secondary-white !important;
    

    &__icon {
      @include widthHeight(44px, 44px);
      border-radius: 4px !important;
      background-color: rgba(255, 255, 255, 0.25) !important;
    }

    &__total {
      font-size: 56px !important;
      font-weight: 600 !important;
      @include RelativePosition(5px, 0, 0, 25px)
    }

    &__title {
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
}

//  daily route section 
.route{
  @include widthHeight(100%, fit-content);
  @include borderLayoutHeader;
  margin-top: 82px !important;
  background-color: $secondary-white !important;
  padding: 26px 20px 42px 22px !important;
    &__header{
      @include flexCenter;
      &__title {
        @include text($title-charcoal, 22px, normal, 30px, 0);
        display:flex;
        div{
        @include text($title-charcoal, 22px, normal, 30px, 0);

        }
      }
      &__link {
        // width: 107px !important;
      @include flexCenter;
      white-space: nowrap;
        span{
        @include text($secondary-Tufts-Blue, 14px, 600, 21px, 0);
        cursor: pointer;
        }
      }
    }
}

.tabs{
  @include widthHeight(100%, 54px);
  margin-bottom: 30px !important;

  &__list{
    height: 54px;
    // @include widthHeight(25%, 54px);
    @include text($secondary-Arsenic, 14px, 600, 20px, 0.1);
    border-bottom: 1px solid $secondary-Arsenic !important;
  }

}

.jss6 {
  opacity: 0;
  transition: all ease 1000ms; /* So that it does not disappear quickly */
}
.jss1.Testimonial_carousel__3rny3:hover .jss6 {
  opacity: 1;
}

.address{
  @include flexCenter;
padding-left:15px;
&__details{
  @include flexCenter;
  margin-left: 13.5px !important;
  width: 307.88px !important;

  &__list{
    div{
      @include text($title-charcoal, 18px, 600, 20px, 0);
      margin-bottom: 8px
    }

    p {
      @include text($title-charcoal, 14px, normal, 20px, 0);
      position: relative;
      left: 14px;
margin-bottom: 15px !important;
    }

  }

}
&__maps{

}
}

//  upcomming insp

.upcomming{
  @include widthHeight(100%, auto);
  @include borderLayoutHeader;
  margin-top: 63px !important;
  background-color: $secondary-white !important;
  padding: 26px 20px 42px 22px !important;
}

@media screen and (min-width: 1440px) {
  .dashboard__summary {
    &__box {
      @include widthHeight(269px, 180px);
    }
  }
}
