// imports
@import "../colors/colors";
@import "../mixins/padding";

@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-Regular.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Open Sans";
    src: url("../../fonts/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

.teams-container {
    padding: 25px 80px 80px 80px;
}
.teams-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    .teams-title {
        font-size: 20px;
        color: $title-charcoal;
    }
    .teams-subtitle {
        color: $secondary-Taupe-Gray;
        font-size: 14px;
        margin: 5px 0px 8px 0px;
    }
    button {
        color: $secondary-white;
    }
}
.teams-table-contaner {
    padding: 12px;
}
.teams-table {
    padding: 0px 10px;
}
.sort-switch {
    margin-right: 6px;
}

.teams-table-wrapper {
    padding: 34px 18px !important;
}
.teams-data-table {
    cursor: pointer;
    &:nth-child(odd) {
        background-color: $secondary-cultered;
    }
    td span{
        font-size: 14px!important;
        line-height: 20px;
    }
}
.teams-table-main thead {
    span {
        font-size: 12px;
        color: $Spanish-Gray2;
    }
    td:first-child {
        padding-left: 15px;
    }
    .action-column {
        padding-right: 25px;
        padding-left: 16px;
        text-align: center;
    }
}
.teams-table-main tbody {
    tr {
        height: 60px;
    }
    td {
        padding: 0px 40px 0px 14px;
        span {
            color: $title-charcoal;
        }
    }
    td:first-child {
        padding-left: 31px;
    }
    td:last-child {
        padding-right: 25px;
        span svg:first-child {
            margin-right: 10px;
        }
    }
    .teams-email-data {
        max-width: 230px;
        span {
            max-width: 220px;
            word-wrap: break-word;
            display: block;
        }
    }
}
.teams-table-main .teams-body {
    tr {
        height: 60px;
    }
    td {
        padding: 0px 40px 0px 14px;
        span {
            color: $title-charcoal;
            max-width: 150px;
            word-wrap: break-word;
            display: block;
        }
        .team-role{
            display: flex;
        }
    }
    td:first-child {
        padding-left: 31px;
    }
    td:last-child {
        padding-right: 25px;
        span svg:first-child {
            margin-right: 10px;
        }
    }
    .teams-email-data {
        max-width: 230px;
        span {
            max-width: 220px;
            word-wrap: break-word;
            display: block;
        }
    }
}
.phone-data {
    white-space: nowrap;
}
.table-actions span {
    display: flex!important;
    justify-content: center!important;
}
.teams-new-button {
    width: 119px;
    height: 34px;
    font-weight: bold;
}
.head-title {
    svg {
        font-size: 13px;
        margin-left: 1px;
        margin-bottom: 3px;
    }
}

.team-role {
    display: flex;
    align-items: center;
    svg {
        margin-right: 6px;
        font-size: 16px;
    }
}

.owner-icon {
    color: $Tufts-Blue;
    font-size: 16px !important;
}
// dialog styles
.dialog-container h2 {
    font-size: 22px !important;
}
.dialog-content {
    margin-top: 2px;
    padding: 20px 41px 20px 34px !important;
    .input-form-label {
        color: $primary-Sonic-Silver;
        opacity: 57%;
        span {
            color: $severity-Electric-Red;
        }
    }
}
.close-wrapper {
    position: relative;
    svg {
        position: absolute;
        top: 7px;
        right: 12px;
        cursor: pointer;
    }
}
.dialog-container .MuiPaper-elevation.MuiPaper-rounded {
    max-width: 970px !important;
    padding: 9px 28px 10px 9px;
}
.delete-btn {
    width: 121px !important;
    height: 34px !important;
    font-size: 13px !important;
    border-radius: 2px !important;
    font-weight: 600 !important;
}
.delete-alert-modal {   
    .MuiDialog-paper {
        padding: 50px 65px 36px 65px;
        align-items: center;        
        width: 480px;
        max-width: 480px;
    }
    h2{
        color: $title-charcoal;
        font-size: 22px;
        line-height: 30px;
        padding: 9px;
    }
    .MuiDialogActions-root{
        justify-content: center;
    }
    p{
        font-size: 14px;
        line-height: 22px;
        color: $title-charcoal;
        margin-bottom: 12px;
    }
    .close-icon{
        color: $text-Black-Olive;
    }
}
.dialog-action button {
    margin-top: 15px;
    color: $secondary-white;
}
.field-reset {
    margin-bottom: 2px !important;
}
.mail-info,
.role-info {
    font-size: 12px;
    color: $title-charcoal;
    padding-left: 18px;
    display: flex;
}
.info-icon {
    font-size: 12px !important;
    margin-left: 3px;
    cursor: pointer;
}
// details styles
.arrowDown-icon {
    position: absolute;
    right: 10%;
    cursor: pointer;
}
.role-selection {
    padding: 10px;
}
.teams-container .details-title .MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.teams-container .details-title .Mui-focused {
    border: none !important;
}
.role-wrapper {
    display: flex;
    align-items: center;
}
.teams-container .MuiSelect-select {
    padding: 10px 4px 4px 8px !important;
}
.company-pagination .MuiSelect-select{
   padding: 0px 25px 0px 5px !important;
}
.team-profile-header {
    padding: 14px 16px 13px 16px !important;
    span {
        font-size: 20px !important;
        color: $title-charcoal !important;
    }
    svg {
        font-size: 18px !important;
        color: $title-charcoal;
        cursor: pointer;
    }
    .MuiCardHeader-action {
        margin: 1px 0px 0px 0px !important;
        padding: 0px !important;
    }
}
.prof-img-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    p{
      display:flex;
      align-items: center;
      cursor: pointer;
      padding: 5px;
      gap: 5px;
      color: #E30303;
      border: none;
      padding-left: 14px;
      margin-bottom: 3px;
      svg{
        font-size: 16px;
      }
    }
}
.team-prof-img {
    padding: 16px !important;
    max-height: 203px;
    object-fit: contain !important;
    pointer-events: none
}
.team-permission-card {
    padding: 26px 16px;
    div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 20px;
            color: $Tufts-Blue;
        }
    }
    div:nth-child(2) {
        font-size: 20px;
        color: $title-charcoal;
    }
    .team-permission-text {
        color: $title-charcoal;
    }
}
.team-details-card {
    padding: 18px 28px 62px 18px;
}
.details-title {
    margin-bottom: 21px !important;
    span {
        font-size: 22px;
        color: $title-charcoal;
        line-height: 30px;
    }
    p {
        font-size: 14px;
        color: $title-charcoal;
        line-height: 22px;
        margin-top: 6px;
    }
}
.zip-field fieldset{
    letter-spacing: 0px!important;
}
.team-details-cardTitle {
    margin-bottom: 16px!important;
    padding-left: 15px;
    span {
        font-size: 20px;
    }
}
.permission_change_modal {
    padding: 20px 50px;
    justify-content: center;
    p:first-child {
        font-size: 22px;
        color: $title-charcoal;
        margin-bottom: 13px;
        text-align: center;
    }
    p:last-child {
        text-align: center;
        font-size: 14px;
        color: $title-charcoal;
    }
}
.dialog-container-details .dialog-action {
    margin-bottom: 30px;
    padding-right: 26px;
}
.forms-control {
    .MuiSelect-select.MuiSelect-outlined {
        height: 28px !important;
    }
}
.mb-5 {
    margin-bottom: 5px;
    display: inline-block;
}
.teams-detail-btn {
    color: $secondary-white !important;
}
.disabled-permision-btn{
    border: 1px solid #d8d8d8 !important;
    color: #ffffff !important;
    border-radius: 0px !important;
    background-color: $secondary-Gray !important;
    box-shadow: none !important;
    width: 119px !important;
    text-transform: none !important;
    height: 34px !important;
    position: relative !important;
    top: 10px;
    font-family: "Open Sans" !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    letter-spacing: 0.5px !important;
    line-height: 24px !important;
    cursor: pointer;
}
.team-detail-cta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    margin-top: 20px !important;
    button:first-child {
        margin-right: 10px;
    }
}
@media only screen and (max-width: 900px) {
    .teams-container {
        padding: 25px 20px 80px;
    }
}
@media only screen and (max-width: 600px) {
    .teams-container {
        padding: 15px 0px 80px;
    }
    .dialog-container {
        padding: 9px 8px 30px 2px;
    }
    .permission_change_modal {
        padding: 20px 5px;
        justify-content: center;
    }
    .team-user-name {
        padding-left: 16px;
    }
    .dialog-content {
        padding: 20px 0px !important;
    }
    .team-detail-cta {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 15px;
        button:first-child {
            margin-right: 10px;
        }
    }
    .delete-alert-modal {   
        .MuiDialog-paper {
            padding: 20px 15px 20px 15px;
        }
    }
}
