@import "../colors/colors";
@import "../mixins/padding";
@import "../mixins/_fonts.scss";
@import "../mixins/_styles.scss";

.inspection-details {
  padding: 40px 80px;
  color: $white-smoke;
  overflow-y: scroll !important;
  height: calc(100vh - 40px - 60px) !important;
}

.detail-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.layout {
  width: 65.46%;
}

.layout2 {
  width: 31.35%;
}

.layout__header {
  display: block;
}

.layout__header1 {
  display: none;
}

.layout__title {
  @include text($title-charcoal, 22px, normal, 30px, 0);
  word-break: break-all;
}

.layout__description {
  text-align: left;
  font-size: 14px;
  padding-top: 5px;
  color: $title-charcoal;
}

.layout__tabs {
  width: 100%;
  display: flex;
  margin-top: 26px;
  justify-content: flex-start;
  border-bottom: 1px solid $secondary-Light-Grey;
}

.layout__tabs__select {
  color: $primary-Jungle-Green;
  width: 100%;
}

.layout__tabs__selected {
  @include text($secondary-Arsenic, 14px, 600, 20px, 0.1px);
  width: 25%;
}

.details {
  @include commonLayoutWidth(100%, 39px, 0, 25px 0 0 0);
  @include flexCenter;
  @include BgTexture;
  @include borderLayoutHeader;
  border-radius: 0px;
}

.details__title {
  color: $title-charcoal;
  margin-left: 16px;
  font-size: 18px;
  width: 80%;
  white-space: normal;
}

.details__link {
  width: 30%;
  display: flex;
  color: $secondary-Tufts-Blue;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding-right: 16px;
}

.details__icon {
  color: $secondary-Tufts-Blue;
  width: 10.5px;
  height: 10.5px;
  cursor: pointer;
  padding-right: 9px;
}

.details__layout {
  width: 100%;
  display: flex;
  border: 1px solid $secondary-platinum;
  background-color: $secondary-white;
  border-top: none;
}

.details__text {
  font-size: 12px;
  color: $secondary-Tufts-Blue;
  font-weight: 600;
  cursor: pointer;
}

.details__img {
  height: 189.85px;
  width: 34.73%;
  margin-right: 27px;
  overflow: hidden;
}

.details__image {
  height: 189.85px;
  width: 100%;
}

.details__address {
  width: 67.27%;
}

.heading {
  font-size: 12px;
  color: $secondary-Taupe-Gray;
}

.description {
  font-size: 14px;
  color: $title-charcoal;
}

.gridBox {
  display: flex;
  justify-content: space-between;
  width: 346px;
  flex-wrap: wrap;
}

.grid__container {
  padding-top: 14px;
  padding-right: 32px;
}

.map-container {
  border: 1px solid $secondary-platinum;
  width: 100%;
  height: 219px;
}

.status {
  border: 1px solid $secondary-platinum;
  padding: 15px 22px 21px 18px;
  background-color: $secondary-white;
}

.button {
  padding: 10px 20px;
  background-color: $text-lavender-blue;
  border: none;
  border-radius: 18px;
  font-size: 14px !important;
  font-weight: bold;
  color: $text-egyptian-blue;
}

.action {
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
  margin-top: 32px;
}

.cancel-icon {
  color: $severity-Electric-Red;
  height: 13.33px;
  width: 13.33px;
  margin-right: 8.33px;
}

.check-icon {
  color: $secondary-Tufts-Blue;
  height: 13.33px;
  width: 13.33px;
  margin-right: 8.33px;
}

.selected-icon {
  color: $secondary-Celadon-green;
  // width: 24px;
  // height: 24px;
}

.calender-icon {
  height: 45.83px;
  width: 45.83px;
  color: $text-Black-Olive;
  margin-right: 10.75px;
}

.items {
  display: flex;
  margin-bottom: 33px;
}

.date {
  display: flex;
  margin-top: 15px;
}

.stepper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12.5px;
}

.stepper__status {
  width: 20px;
  height: 20px;
  color: $secondary-Celadon-green;
}

.stepper__progress {
  background-color: $primary-Platinum;
  border: none;
  width: 100%;
  height: 4px;
}

.bubble {
  position: relative;
  border-radius: 10px;
  // box-shadow: 2px 2px 2px rgba(0,0,0, 0.3);
  display: inline-block;
  vertical-align: top;
  border: 1px solid $secondary-Light-Grey;
  margin: 11px 8px;
}
.bubble.arrow::before,
.bubble.arrow::after {
  position: absolute;
  background-color: #fff;
  content: "\00a0";
  display: block;
  height: 16px;
  width: 26px;
  left: -4.75px;
  bottom: -10px;
  transform: rotate(-45deg) skew(-45deg);
}
.bubble.arrow::before {
  background-color: transparent;
  width: 30px;
}
.bubble.arrow::after {
  bottom: -8px;
  border: 3px solid $secondary-Light-Grey;
  border-width: 0 1px 1px 1px;
}

.bubble > .content {
  position: relative;
  background-color: #fff;
  color: $title-charcoal;
  border-radius: 10px;
  z-index: 1;
  padding: 11px 10px;
}
.service_component {
  // min-height: 239px;
  border: 1px solid #e7e7e9;
}

.bubble.small,
.bubble.small > .content {
  border-radius: 5px;
}

@media only screen and (max-width: 900px) {
  .inspection-details {
    padding: 0px;
  }

  .detail-card {
    flex-direction: column-reverse;
  }

  .layout {
    width: 100%;
  }

  .layout2 {
    width: 100%;
  }

  .details__layout {
    flex-direction: column;
  }

  .details__img {
    height: 199px;
    width: 100%;
  }

  .details__address {
    width: 90%;
    margin: 0 auto;
  }

  .details__link {
    width: 40%;
  }

  .layout__header {
    display: none;
  }

  .layout__header1 {
    display: block;
    padding-top: 47px;
    padding-bottom: 13px;
    padding-left: 16px;
  }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1024px) {
  .layout {
    width: 62%;
  }

  .layout2 {
    width: 35%;
  }

  .details__layout {
    flex-direction: column;
  }

  .details__img {
    height: 199px;
    width: 100%;
  }

  .details__address {
    width: 90%;
    margin: 0 auto;
  }

  .inspection-details {
    padding: 40px;
  }

  .details__title {
    font-size: 14px;
  }

  .status {
    padding: 15px;
  }

  .details__link {
    width: 36%;
  }
}

.activity-table-head {
  position: relative;
  color: $Spanish-Gray2;
  font-size: 12px;
  line-height: 19.14px;
}
.activity-table-head:nth-child(1) {
  position: relative;
  padding-left: 20px;
  padding-right: 50px;
}
.activity-table-head:nth-child(2) {
  position: relative;
}

//notification tab
.notification-email-body {
  color: $title-charcoal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-top: 15px;
  white-space: pre-wrap !important;
  padding-right: 10px;
}
.notification-read-moreless-button {
  color: $primary-Jungle-Green !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
  height: 22px !important;
  padding: 0px !important;
}
.notification-email-tocc {
  color: $title-charcoal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  height: 24px;
}
.notification-resend-button {
  width: 99px !important;
  height: 34px !important;
  border: 1px solid $primary-Jungle-Green !important;
  border-radius: 1px !important;
  background-color: $secondary-white !important;
  color: $text-Black-Olive !important;
  font-weight: 600 !important;
}
.notification-resend-confirm {
  color: $title-charcoal !important;
  font-size: 14px !important;
  margin-top: 15px !important;
  letter-spacing: 0 !important;
  font-family: "open sans" !important;
}
.notification-resend-confirm-heading {
  color: $title-charcoal !important;
  font-size: 22px !important;
  margin-top: 15px !important;
  letter-spacing: 0 !important;
  font-family: "open sans" !important;
}
.notification-resend-close-icon {
  float: right !important;
  cursor: pointer !important;
}
.notification-resend-button-container {
  display: flex !important;
  justify-content: center !important;
  margin-top: 25px !important;
}
.notification-send-status {
  color: $secondary-Tufts-Blue;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  height: 24px;
  width: 52px;
}
.notification-send-status-icon {
  height: 13.93px !important;
  width: 15.73px !important;
}
.notification-paper {
  min-height: 169px !important;
  border: 1px solid $secondary-platinum !important;
  width: 100% !important;
}
.notification-date-format {
  color: $Dark-Charcoal2;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  height: 19px;
  width: 90px;
}
.notification-time-format {
  color: $title-graphite;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0;
  height: 15px;
  width: 83px;
}
.notification-subject {
  color: $title-charcoal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  height: 24px;
  font-weight: 600;
}
.notification-resend-cancel-button {
  width: 119px;
  box-shadow: none !important;
  border-radius: 0px !important;
  color: $primary-Spanish-Gray !important;
  border: 1px solid $button-border !important;
}
.notification-alert-message {
  margin: 16px 16px 0px 16px !important;
}

.notification-divider {
  margin-top: 26px !important;
}
.notification-resend-margin {
  margin-top: 20px;
}
.notification-resend-alert {
  position: relative;
  top: 45%;
  left: 50%;
  margin-bottom: 20%;
  transform: translate(-50%, -25%);
  max-width: 480px;
  height: 231px;
  border-radius: 5px;
  background-color: $secondary-white;
  border: 2px solid $secondary-platinum;
  padding: 4px;
  outline: 0;
}
.notification-align {
  text-align: center !important;
}

//request payment iFrame css
.request-payment {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  position: fixed;
  overflow: hiddenx;
}
