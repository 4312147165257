@import "../colors/colors";
@import "../mixins/padding";

.agent-table-head {
  position: relative;
  width: 248px;
  color: #9a9a9a;
  font-size: 12px;
  line-height: 19.14px;
}
.agent-table-head:nth-child(2) {
  position: relative;
  min-width: 125px;
}
.agent-table-head:nth-child(3) {
  position: relative;
  min-width: 120.62px;
}
.agent-table-head:nth-child(4) {
  position: relative;
  min-width: 106.15px;
}

.agent-table-head:nth-child(5) {
  position: relative;
  min-width: 106.15px;
  margin-left: 25.08px;
}
.agent-table-head:nth-child(6) {
  position: relative;
  max-width: 239.32px !important;
  word-wrap: break-word !important;
  margin-left: 22.19px;
}
.agent-table-head:nth-child(7) {
  position: relative;
  min-width: 106.15px;
}

.agent-table-head:nth-child(8) {
  position: relative;
  min-width: 126px;
  margin-left: 23px;
}
.agent-table-head:nth-child(9) {
  position: relative;
  min-width: 56px;
  margin-left: 20px;
  margin-right: 23px;
}
.agent-row-data-font {
  font-size: 13px;
}
.agent-row-data-email {
  width: 248px;
  font-size: 13px;
  white-space: pre-line;
}

.changes_status{
  position: relative;
  top: -10px;
  color: #F35F01;
  font-family: "Open Sans";
  font-size: 9px;
  padding: 2px 8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 9px;
  text-align: center;
  border-radius: 15px;
  background-color: #FFECD2;
}

.edit-icon-template-list {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
.edit-icon.disabled {
  color: $text-Black-Olive;
  opacity: 0.25;
  &:hover {
    cursor: pointer;
    opacity: 0.25;
  }
}
.delete-icon {
  color: #e30303;
  opacity: 0.25;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}
.delete-icon-TE {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    color: #e30303;
    cursor: pointer;
    opacity: 1;
  }
}
.edit-icon-TE {
  color: #3b3b3b;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}